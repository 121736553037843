
const template = {}

const elObj = require.context('./../template/', true, /\.vue$/)
elObj.keys().forEach(key => {
  const el = elObj(key).default
  template[el.name] = el
})

export default template

